<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-sheet
      tile
      class="col-md-12 col-lg-12 col-sm-12 col-xs-12"
      v-if="$vuetify.breakpoint.smAndUp"
    >
      <v-row>
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
          @click="newMonthCalender"
        >
          <span>Month</span>
        </v-btn>
     
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            label="Go To Date"
            v-bind="attrs"
            color="#38227A"
            dark
            class="text-capitalize px-7 mr-5"
            height="45px"
            style="border-radius: 10px; margin-top: 20px; margin-left:21px"
            v-on="on"
          >
            <span>Go To Date</span>
          </v-btn>
        </template>
        <v-date-picker
          v-model="list_date"
          no-title
          @input="menu = false"
          @change="getTodayCalendarData()"
        ></v-date-picker>
      </v-menu>
      <v-btn
        color="#38227A"
        dark
        class="px-2"
        style="
          border-radius: 10px;
          margin: 20px 5px;
          margin-left: 13px;
          height: 44px;
          min-width: 90px;
        "
        @click="toggleAddFilterModal({ show: true, type: 'add' })"
      >
        <span style="margin-left: 5px; text-transform: capitalize">Filter</span>
      </v-btn>
      <!--  <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
          @click="getTodayCalendarData()"
        >
          <span>Today</span>
        </v-btn>-->

    </v-row>
    </v-sheet>
   <v-sheet
      tile
      class="col-md-4 col-lg-4 col-sm-12 col-xs-12"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-row>
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="22px"
          style="border-radius: 10px; margin: 0px 0px;width: 10px;
    font-size: 11px;"
          @click="newMonthCalender"
        >
          <span>Month</span>
        </v-btn>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              label="Go To Date"
              v-bind="attrs"
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              height="22px"
              style="border-radius: 10px;width:90px;
    font-size: 11px;"
              v-on="on"
            >
              <span>Go To Date</span>
            </v-btn>
          </template>
          <v-date-picker
            v-model="list_date"
            no-title
            @input="menu = false"
            @change="getTodayCalendarData()"
          ></v-date-picker>
        </v-menu>
         <v-btn
        color="#38227A"
        dark
        class="px-2"
        style="
          border-radius: 10px;
               height: 22px;
    min-width: 55px;
    font-size: 11px;
        "
        @click="toggleAddFilterModal({ show: true, type: 'add' })"
      >
        <span style="text-transform: capitalize">Filter</span>
      </v-btn>
      </v-row>
    </v-sheet>
    <!--  <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
          @click="getTodayCalendarData()"
        >
          <span>Today</span>
        </v-btn>-->

    <br />
    <img
      src="@/assets/thingsToDo/loader5.gif"
      width="100"
      height="100"
      style="margin-left: 42%"
      v-if="loading"
    />

    <div >
      <h3 v-if="this.list_date">
        <span style="margin-left: 25px">{{ dayOfWeek }}</span>
        <br />
        <v-icon
          @click="
            goToBackDateList();
            getTodayCalendarData();
          "
          >mdi-arrow-left</v-icon
        >{{ formattedDate }}

        <v-icon
          @click="
            goToNextDateList();
            getTodayCalendarData();
          "
          >mdi-arrow-right</v-icon
        >
      </h3>
      <h3 v-else>
        <span style="margin-left: 25px" v-if="!this.nextFlag">{{ today }}</span>
        <span style="margin-left: 25px" v-else>{{ dayOfWeek1 }}</span>
        <br />
        <v-icon
          @click="
            goToBackDate();
            getWeekDayCalendarData();
          "
          >mdi-arrow-left</v-icon
        >{{ this.currentDateDisplay1
        }}<v-icon
          @click="
            goToNextDate();
            getWeekDayCalendarData();
          "
          >mdi-arrow-right</v-icon
        >
      </h3>
      <!-- <button @click="goToNextDate();getWeekDayCalendarData()">Go to Next Date</button>
    <p>Next Date: {{ nextDate }}</p>-->
        <template >
        <div v-for="item in listcalendar.items" :key="item.id" >
          <div style="font-size: 14px" v-if="$vuetify.breakpoint.smAndUp">
            <div class="text-left row-item" v-if="item.event_type == 'Pto'">
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span style="font-size: 16px; margin-left: -200px">PTO</span
                >&nbsp;<span style="margin-left: 145px">{{
                  item.start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <strong
                  style="color: #a3c585; cursor: pointer"
                  @click="
                    toggleListPtoInfoModal({
                      show: true,
                      id: item.id,
                    })
                  "
                  >PTO({{ item.player_name }})</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'Task'"
            >
              <span style="font-size: 16px; margin-left: -200px">Task</span>
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span
                  style="font-size: 16px; color: #f9b4f6; cursor: pointer"
                  @click="
                    toggleListTaskInfoModal({
                      show: true,
                      id: item.id,
                    })
                  "
                  >{{ item.task_title }}</span
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'assembly'"
            >
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span style="font-size: 16px; margin-left: -200px"
                  >Free Assembly</span
                >&nbsp;<span style="margin-left: 75px">{{
                  item.assemblies[0].start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #41a7f5; cursor: pointer"
                  @click="
                    toggleListAssemblyInfoModal({
                      show: true,
                      id: item.assemble_id,
                    })
                  "
                  v-if="item.assemblies[0].team_name"
                >
                  {{ item.assemblies[0].assemble_city }},{{
                    item.assemblies[0].assemble_state
                  }}-FA({{ item.assemblies[0].sequence }})({{
                    item.assemblies[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #41a7f5; cursor: pointer"
                  @click="
                    toggleListAssemblyInfoModal({
                      show: true,
                      id: item.assemble_id,
                    })
                  "
                  v-else-if="item.assemblies[0].player_list != null"
                >
                  {{ item.assemblies[0].assemble_city }},{{
                    item.assemblies[0].assemble_state
                  }}-FA({{ item.assemblies[0].sequence }})({{
                    item.assemblies[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListAssemblyInfoModal({
                      show: true,
                      id: item.assemble_id,
                    })
                  "
                  v-else
                >
                  {{ item.assemblies[0].assemble_city }},{{
                    item.assemblies[0].assemble_state
                  }}-FA({{ item.assemblies[0].sequence }})(Unassigned)
                </strong>
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'game'"
            >
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span style="font-size: 16px; margin-left: -200px">Game</span
                >&nbsp;<span style="margin-left: 137px">{{
                  item.games[0].start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #27e600; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-if="item.games[0].team_name == 'ALLSTAR'"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #ca5cdd; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else-if="item.games[0].team_name == 'EMPIRE'"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #b96a59; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else-if="item.games[0].team_name == 'LEGENDS'"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else-if="item.games[0].team_name == null"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}(Unassigned)
                </strong>
                <strong
                  style="color: #ffcccb; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'hotel'"
            >
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
                v-if="item.hotel_detail[0].game != null"
              >
                <span style="font-size: 16px; margin-left: -200px">Hotel</span
                >&nbsp;<span style="margin-left: 145px"></span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-if="item.hotel_detail[0].team_name != null"
                >
                  {{ item.hotel_detail[0].hotel_city }},{{
                    item.hotel_detail[0].hotel_state
                  }}-Hotel({{ item.hotel_detail[0].team_name }})
                </strong>
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else-if="item.hotel_detail[0].player_list != null"
                >
                  {{ item.hotel_detail[0].hotel_city }},{{
                    item.hotel_detail[0].hotel_state
                  }}-Hotel({{
                    item.hotel_detail[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else
                >
                  {{ item.hotel_detail[0].hotel_city }},{{
                    item.hotel_detail[0].hotel_state
                  }}-Hotel(Unassigned)
                </strong>
              </div>

              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
                v-else-if="item.hotel_detail[0].game == null"
              >
                <span style="font-size: 16px; margin-left: -200px">Hotel</span
                >&nbsp;<span style="margin-left: 145px"></span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-if="item.hotel_detail[0].team_name != null"
                >
                  {{ item.hotel_detail[0].city }},{{
                    item.hotel_detail[0].hotel_state_paid
                  }}-Hotel({{ item.hotel_detail[0].team_name }})
                </strong>
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else-if="item.hotel_detail[0].player_list != null"
                >
                  {{ item.hotel_detail[0].city }},{{
                    item.hotel_detail[0].hotel_state_paid
                  }}-Hotel({{
                    item.hotel_detail[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else
                >
                  {{ item.hotel_detail[0].city }},{{
                    item.hotel_detail[0].hotel_state_paid
                  }}-Hotel(Unassigned)
                </strong>
              </div>
              <br />
            </div>

            <div
              class="text-left row-item"
              v-if="item.event_type == 'kick_off_date'"
            >
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span style="font-size: 16px; margin-left: -200px">Wizfit</span
                >&nbsp;<span style="margin-left: 137px">{{
                  item.campaigns[0].kick_off_start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.school_name != null &&
                    item.campaigns[0].kick_off_team_name != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-KO({{
                    item.campaigns[0].kick_off_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].kick_off_player_list != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-KO({{
                    item.campaigns[0].kick_off_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].kick_off_player_list == null &&
                    item.campaigns[0].kick_off_team_name == null
                  "
                  >WF-{{ item.city }},{{
                    item.state
                  }}-KO(Unassigned)</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.district_name != null &&
                    item.campaigns[0].kick_off_team_name != null
                  "
                  >WF-{{ item.district_name }}-KO({{
                    item.campaigns[0].kick_off_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].kick_off_player_list != null
                  "
                  >WF-{{ item.district_name }}-KO({{
                    item.campaigns[0].kick_off_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].kick_off_player_list == null &&
                    item.campaigns[0].kick_off_team_name == null
                  "
                  >WF-{{ item.district_name }}-KO(Unassigned)</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'gold_ball_giveaway_date'"
            >
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span style="font-size: 16px; margin-left: -200px">Wizfit</span
                >&nbsp;<span style="margin-left: 137px">{{
                  item.campaigns[0].gold_ball_start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.school_name != null &&
                    item.campaigns[0].gold_ball_team_name != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GB({{
                    item.campaigns[0].gold_ball_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].gold_ball_player_list != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GB({{
                    item.campaigns[0].gold_ball_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].gold_ball_player_list == null &&
                    item.campaigns[0].gold_ball_team_name == null
                  "
                  >WF-{{ item.city }},{{
                    item.state
                  }}-GB(Unassigned)</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.district_name != null &&
                    item.campaigns[0].gold_ball_team_name != null
                  "
                  >WF-{{ item.district_name }}-GB({{
                    item.campaigns[0].gold_ball_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].gold_ball_player_list != null
                  "
                  >WF-{{ item.district_name }}-GB({{
                    item.campaigns[0].gold_ball_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].gold_ball_player_list == null &&
                    item.campaigns[0].gold_ball_team_name == null
                  "
                  >WF-{{ item.district_name }}-GB(Unassigned)</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'graduation_celebration_date'"
            >
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span style="font-size: 16px; margin-left: -200px">Wizfit</span
                >&nbsp;<span style="margin-left: 137px">{{
                  item.campaigns[0].graduation_start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.school_name != null &&
                    item.campaigns[0].graduation_team_name != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GR({{
                    item.campaigns[0].graduation_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].graduation_player_list != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GR({{
                    item.campaigns[0].graduation_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].graduation_player_list == null &&
                    item.campaigns[0].graduation_team_name == null
                  "
                  >WF-{{ item.city }},{{
                    item.state
                  }}-GR(Unassigned)</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.district_name != null &&
                    item.campaigns[0].graduation_team_name != null
                  "
                  >WF-{{ item.district_name }}-GR({{
                    item.campaigns[0].graduation_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].graduation_player_list != null
                  "
                  >WF-{{ item.district_name }}-GR({{
                    item.campaigns[0].graduation_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].graduation_player_list == null &&
                    item.campaigns[0].graduation_team_name == null
                  "
                  >WF-{{ item.district_name }}-GR(Unassigned)</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'custom'"
            >
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span style="font-size: 16px; margin-left: -200px"
                  >Custom Event</span
                >
                &nbsp;<span style="margin-left: 75px">{{
                  item.paid_events[0].start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #fbc02d; cursor: pointer"
                  @click="
                    toggleListCustomInfoModal({
                      show: true,
                      id: item.paid_event_id,
                    })
                  "
                  v-if="item.paid_events[0].team_name != null"
                  >{{ item.paid_events[0].event_name }}-{{
                    item.paid_events[0].city
                  }},{{ item.paid_events[0].state_name }}-({{
                    item.paid_events[0].team_name
                  }})</strong
                >
                <strong
                  style="color: #fbc02d; cursor: pointer"
                  @click="
                    toggleListCustomInfoModal({
                      show: true,
                      id: item.paid_event_id,
                    })
                  "
                  v-else-if="item.paid_events[0].player_list != null"
                  >{{ item.paid_events[0].event_name }}-{{
                    item.paid_events[0].city
                  }},{{ item.paid_events[0].state_name }}-({{
                    item.paid_events[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListCustomInfoModal({
                      show: true,
                      id: item.paid_event_id,
                    })
                  "
                  v-else
                  >{{ item.paid_events[0].event_name }}-{{
                    item.paid_events[0].city
                  }},{{
                    item.paid_events[0].state_name
                  }}-(Unassigned)</strong
                >
              </div>
              <br />
            </div>
          </div>

            <div style="font-size: 14px" v-if="$vuetify.breakpoint.xsOnly">
            <div class="text-left row-item" v-if="item.event_type == 'Pto'">
              <div
                style="text-align: justify; font-size: 11px; margin-left: 215px"
              >
                <span style="font-size: 13px; margin-left: -200px">PTO</span
                >&nbsp;<span style="margin-left: 100px">{{
                  item.start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <strong
                  style="color: #a3c585; cursor: pointer"
                  @click="
                    toggleListPtoInfoModal({
                      show: true,
                      id: item.id,
                    })
                  "
                  >PTO({{ item.player_name }})</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'Task'"
            >
              <span style="font-size: 16px; margin-left: -200px">Task</span>
              <div
                style="text-align: justify; font-size: 16px; margin-left: 245px"
              >
                <span
                  style="font-size: 16px; color: #f9b4f6; cursor: pointer"
                  @click="
                    toggleListTaskInfoModal({
                      show: true,
                      id: item.id,
                    })
                  "
                  >{{ item.task_title }}</span
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'assembly'"
            >
              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
              >
                <span style="font-size: 13px; margin-left: -200px"
                  >Free Assembly</span
                >&nbsp;<span style="margin-left: 40px">{{
                  item.assemblies[0].start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #41a7f5; cursor: pointer"
                  @click="
                    toggleListAssemblyInfoModal({
                      show: true,
                      id: item.assemble_id,
                    })
                  "
                  v-if="item.assemblies[0].team_name"
                >
                  {{ item.assemblies[0].assemble_city }},{{
                    item.assemblies[0].assemble_state
                  }}-FA({{ item.assemblies[0].sequence }})({{
                    item.assemblies[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #41a7f5; cursor: pointer"
                  @click="
                    toggleListAssemblyInfoModal({
                      show: true,
                      id: item.assemble_id,
                    })
                  "
                  v-else-if="item.assemblies[0].player_list != null"
                >
                  {{ item.assemblies[0].assemble_city }},{{
                    item.assemblies[0].assemble_state
                  }}-FA({{ item.assemblies[0].sequence }})({{
                    item.assemblies[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListAssemblyInfoModal({
                      show: true,
                      id: item.assemble_id,
                    })
                  "
                  v-else
                >
                  {{ item.assemblies[0].assemble_city }},{{
                    item.assemblies[0].assemble_state
                  }}-FA({{ item.assemblies[0].sequence }})(Unassigned)
                </strong>
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'game'"
            >
              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
              >
                <span style="font-size: 13px; margin-left: -200px">Game</span
                >&nbsp;<span style="margin-left: 94px">{{
                  item.games[0].start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #27e600; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-if="item.games[0].team_name == 'ALLSTAR'"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #ca5cdd; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else-if="item.games[0].team_name == 'EMPIRE'"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #b96a59; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else-if="item.games[0].team_name == 'LEGENDS'"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else-if="item.games[0].team_name == null"
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}(Unassigned)
                </strong>
                <strong
                  style="color: #ffcccb; cursor: pointer"
                  @click="
                    toggleListGameInfoModal({
                      show: true,
                      id: item.game_id,
                    })
                  "
                  v-else
                  >{{ item.games[0].city }},{{
                    item.games[0].state_name
                  }},{{ converTime(item.games[0].start_time) }}({{
                    item.games[0].team_name
                  }})
                </strong>
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'hotel'"
            >
              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
                v-if="item.hotel_detail[0].game != null"
              >
                <span style="font-size: 13px; margin-left: -200px">Hotel</span
                >&nbsp;<span style="margin-left: 80px"></span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-if="item.hotel_detail[0].team_name != null"
                >
                  {{ item.hotel_detail[0].hotel_city }},{{
                    item.hotel_detail[0].hotel_state
                  }}-Hotel({{ item.hotel_detail[0].team_name }})
                </strong>
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else-if="item.hotel_detail[0].player_list != null"
                >
                  {{ item.hotel_detail[0].hotel_city }},{{
                    item.hotel_detail[0].hotel_state
                  }}-Hotel({{
                    item.hotel_detail[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else
                >
                  {{ item.hotel_detail[0].hotel_city }},{{
                    item.hotel_detail[0].hotel_state
                  }}-Hotel(Unassigned)
                </strong>
              </div>

              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
                v-else-if="item.hotel_detail[0].game == null"
              >
                <span style="font-size: 12px; margin-left: -200px">Hotel</span
                >&nbsp;<span style="margin-left: 80px"></span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-if="item.hotel_detail[0].team_name != null"
                >
                  {{ item.hotel_detail[0].city }},{{
                    item.hotel_detail[0].hotel_state_paid
                  }}-Hotel({{ item.hotel_detail[0].team_name }})
                </strong>
                <strong
                  style="color: #e56e94; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else-if="item.hotel_detail[0].player_list != null"
                >
                  {{ item.hotel_detail[0].city }},{{
                    item.hotel_detail[0].hotel_state_paid
                  }}-Hotel({{
                    item.hotel_detail[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})
                </strong>
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListHotelInfoModal({
                      show: true,
                      id: item.hotel_id,
                    })
                  "
                  v-else
                >
                  {{ item.hotel_detail[0].city }},{{
                    item.hotel_detail[0].hotel_state_paid
                  }}-Hotel(Unassigned)
                </strong>
              </div>
              <br />
            </div>

            <div
              class="text-left row-item"
              v-if="item.event_type == 'kick_off_date'"
            >
              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
              >
                <span style="font-size: 13px; margin-left: -200px">Wizfit</span
                >&nbsp;<span style="margin-left:87px">{{
                  item.campaigns[0].kick_off_start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.school_name != null &&
                    item.campaigns[0].kick_off_team_name != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-KO({{
                    item.campaigns[0].kick_off_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].kick_off_player_list != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-KO({{
                    item.campaigns[0].kick_off_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].kick_off_player_list == null &&
                    item.campaigns[0].kick_off_team_name == null
                  "
                  >WF-{{ item.city }},{{
                    item.state
                  }}-KO(Unassigned)</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.district_name != null &&
                    item.campaigns[0].kick_off_team_name != null
                  "
                  >WF-{{ item.district_name }}-KO({{
                    item.campaigns[0].kick_off_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].kick_off_player_list != null
                  "
                  >WF-{{ item.district_name }}-KO({{
                    item.campaigns[0].kick_off_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitInfoModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].kick_off_player_list == null &&
                    item.campaigns[0].kick_off_team_name == null
                  "
                  >WF-{{ item.district_name }}-KO(Unassigned)</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'gold_ball_giveaway_date'"
            >
              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
              >
                <span style="font-size: 13px; margin-left: -200px">Wizfit</span
                >&nbsp;<span style="margin-left: 87px">{{
                  item.campaigns[0].gold_ball_start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.school_name != null &&
                    item.campaigns[0].gold_ball_team_name != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GB({{
                    item.campaigns[0].gold_ball_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].gold_ball_player_list != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GB({{
                    item.campaigns[0].gold_ball_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].gold_ball_player_list == null &&
                    item.campaigns[0].gold_ball_team_name == null
                  "
                  >WF-{{ item.city }},{{
                    item.state
                  }}-GB(Unassigned)</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.district_name != null &&
                    item.campaigns[0].gold_ball_team_name != null
                  "
                  >WF-{{ item.district_name }}-GB({{
                    item.campaigns[0].gold_ball_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].gold_ball_player_list != null
                  "
                  >WF-{{ item.district_name }}-GB({{
                    item.campaigns[0].gold_ball_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGBModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].gold_ball_player_list == null &&
                    item.campaigns[0].gold_ball_team_name == null
                  "
                  >WF-{{ item.district_name }}-GB(Unassigned)</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'graduation_celebration_date'"
            >
              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
              >
                <span style="font-size: 13px; margin-left: -200px">Wizfit</span
                >&nbsp;<span style="margin-left:87px">{{
                  item.campaigns[0].graduation_start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.school_name != null &&
                    item.campaigns[0].graduation_team_name != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GR({{
                    item.campaigns[0].graduation_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].graduation_player_list != null
                  "
                  >WF-{{ item.city }},{{ item.state }}-GR({{
                    item.campaigns[0].graduation_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.school_name != null &&
                    item.campaigns[0].graduation_player_list == null &&
                    item.campaigns[0].graduation_team_name == null
                  "
                  >WF-{{ item.city }},{{
                    item.state
                  }}-GR(Unassigned)</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-if="
                    item.district_name != null &&
                    item.campaigns[0].graduation_team_name != null
                  "
                  >WF-{{ item.district_name }}-GR({{
                    item.campaigns[0].graduation_team_name
                  }})</strong
                >
                <strong
                  style="color: #8c6ee39e; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].graduation_player_list != null
                  "
                  >WF-{{ item.district_name }}-GR({{
                    item.campaigns[0].graduation_player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListWizfitGRModal({
                      show: true,
                      id: item.campaign_id,
                    })
                  "
                  v-else-if="
                    item.district_name != null &&
                    item.campaigns[0].graduation_player_list == null &&
                    item.campaigns[0].graduation_team_name == null
                  "
                  >WF-{{ item.district_name }}-GR(Unassigned)</strong
                >
              </div>
              <br />
            </div>
            <div
              class="text-left row-item"
              v-if="item.event_type == 'custom'"
            >
              <div
                style="text-align: justify; font-size: 11px; margin-left: 219px"
              >
                <span style="font-size: 13px; margin-left: -200px"
                  >Custom Event</span
                >
                &nbsp;<span style="margin-left: 35px">{{
                  item.paid_events[0].start_time_str
                }}</span
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong
                  style="color: #fbc02d; cursor: pointer"
                  @click="
                    toggleListCustomInfoModal({
                      show: true,
                      id: item.paid_event_id,
                    })
                  "
                  v-if="item.paid_events[0].team_name != null"
                  >{{ item.paid_events[0].event_name }}-{{
                    item.paid_events[0].city
                  }},{{ item.paid_events[0].state_name }}-({{
                    item.paid_events[0].team_name
                  }})</strong
                >
                <strong
                  style="color: #fbc02d; cursor: pointer"
                  @click="
                    toggleListCustomInfoModal({
                      show: true,
                      id: item.paid_event_id,
                    })
                  "
                  v-else-if="item.paid_events[0].player_list != null"
                  >{{ item.paid_events[0].event_name }}-{{
                    item.paid_events[0].city
                  }},{{ item.paid_events[0].state_name }}-({{
                    item.paid_events[0].player_list
                      .map((player) => player.name)
                      .join(",")
                  }})</strong
                >
                <strong
                  style="color: #a9a9a9; cursor: pointer"
                  @click="
                    toggleListCustomInfoModal({
                      show: true,
                      id: item.paid_event_id,
                    })
                  "
                  v-else
                  >{{ item.paid_events[0].event_name }}-{{
                    item.paid_events[0].city
                  }},{{
                    item.paid_events[0].state_name
                  }}-(Unassigned)</strong
                >
              </div>
              <br />
            </div>
          </div>
          </div>
        </template>
    </div>
    <add-filter-modal></add-filter-modal>
    <list-game-info-modal></list-game-info-modal>
    <list-assembly-info-modal></list-assembly-info-modal>
    <list-hotel-info-modal></list-hotel-info-modal>
    <list-task-info-modal></list-task-info-modal>
    <list-pto-info-modal></list-pto-info-modal>
    <list-custom-info-modal></list-custom-info-modal>
    <list-wizfit-info-modal></list-wizfit-info-modal>
    <list-wizfit-GB-modal></list-wizfit-GB-modal>
    <list-wizfit-GR-modal></list-wizfit-GR-modal>
  </div>
</template>
<script>
import { API_CALENDAR_GET_DATA } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "../../constants/urls";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "ListCalendarManagement",
  components: {
    ListGameInfoModal: () =>
      import("./ListGameInfoModal/ListGameInfoModal.vue"),
    ListAssemblyInfoModal: () =>
      import("./ListAssemblyInfoModal/ListAssemblyInfoModal.vue"),
    ListTaskInfoModal: () =>
      import("./ListTaskInfoModal/ListTaskInfoModal.vue"),
    ListPtoInfoModal: () => import("./ListPtoInfoModal/ListPtoInfoModal.vue"),
    ListCustomInfoModal: () =>
      import("./ListCustomInfoModal/ListCustomInfoModal.vue"),
    ListHotelInfoModal: () =>
      import("./ListHotelInfoModal/ListHotelInfoModal.vue"),
    ListWizfitInfoModal: () =>
      import("./ListWizfitInfoModal/ListWizfitInfoModal.vue"),
    ListWizfitGBModal: () =>
      import("./ListWizfitGBModal/ListWizfitGBModal.vue"),
    ListWizfitGRModal: () =>
      import("./ListWizfitGRModal/ListWizfitGRModal.vue"),
    AddFilterModal: () => import("./AddFilterModal/AddFilterModal.vue"),
  },
  data() {
    return {
      dataOptions: { sortBy: [], sortDesc: [] },
      assemblyData: {},
      campaignData: {},
      gameData: {},
      listcalendar: { items: [] },
      menu: false,
      list_date: "",
      list_date1: "",
      date: "",
      nextDate: "",
      gameflag: false,
      currentDateDisplay: this.currentDate(),
      currentDateDisplay1: this.currentDate1(),
      loading: false,
      nextFlag: false,
      nextFlagList: false,
      eventcategoryid: localStorage.getItem("eventid"),
      teamID: localStorage.getItem("teamId"),
      playerID: localStorage.getItem("playerId"),
      startdatefmin: localStorage.getItem("startdatefilter"),
      enddatefmin: localStorage.getItem("enddatefilter"),
      eventSupport: localStorage.getItem("eventsupport"),
      salesrepresentative: localStorage.getItem("salesrep"),
    };
  },
  created() {},
  computed: {
    formattedDate() {
      // Use moment.js to format the date
      return moment(this.list_date).format("MM-DD-YYYY");
    },
    dayOfWeek() {
      // Use moment.js to get the day of the week
      return moment(this.list_date).format("dddd");
    },
    dayOfWeek1() {
      // Use moment.js to get the day of the week
      return moment(this.currentDateDisplay).format("dddd");
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      // calendar Modal
      toggleListGameInfoModal: "calendarManagement/toggleListGameInfoModal",
      toggleListAssemblyInfoModal:
        "calendarManagement/toggleListAssemblyInfoModal",
      toggleListTaskInfoModal: "calendarManagement/toggleListTaskInfoModal",
      toggleListPtoInfoModal: "calendarManagement/toggleListPtoInfoModal",
      toggleListCustomInfoModal: "calendarManagement/toggleListCustomInfoModal",
      toggleListWizfitInfoModal: "calendarManagement/toggleListWizfitInfoModal",
      toggleListWizfitGBModal: "calendarManagement/toggleListWizfitGBModal",
      toggleListWizfitGRModal: "calendarManagement/toggleListWizfitGRModal",
      toggleListHotelInfoModal: "calendarManagement/toggleListHotelInfoModal",
      toggleAddFilterModal: "calendarManagement/toggleFilterModal",
      showToast: "snackBar/showToast",
    }),
    getTodayCalendarData() {
      this.events = [];
      const self = this;
      self.loading = true;
      console.log("in mounted");
      const successHandler = (res) => {
        this.listcalendar.items = res.data.calendar_list;
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      if (this.list_date) {
        formData.start_date = this.list_date;
        formData.end_date = this.list_date;
      }
      formData.event_category_id = this.eventcategoryid;
      formData.event_support_id = this.eventSupport;
      formData.sales_rep_id = this.salesrepresentative;
      formData.player_id = this.playerID;
      if (this.teamID != "null") {
        formData.team_id = this.teamID;
      }
      localStorage.setItem("filterdata", this.eventcategoryid);
      Axios.request_GET(
        API_CALENDAR_GET_DATA,

        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    getWeekDayCalendarData() {
      this.events = [];
      const self = this;
      self.loading = true;
      console.log("in mounted");
      const successHandler = (res) => {
        this.listcalendar.items = res.data.calendar_list;

        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      if (this.nextFlag) {
        formData.start_date = this.nextDate;
        formData.end_date = this.nextDate;
      }
      if (!this.nextFlag) {
        formData.start_date = this.currentDate();
        formData.end_date = this.currentDate();
      }
      formData.event_category_id = this.eventcategoryid;
      formData.event_support_id = this.eventSupport;
      formData.sales_rep_id = this.salesrepresentative;
      formData.player_id = this.playerID;
      if (this.teamID != "null") {
        formData.team_id = this.teamID;
      }
      localStorage.setItem("filterdata", this.eventcategoryid);
      Axios.request_GET(
        API_CALENDAR_GET_DATA,

        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
      return date;
    },
    currentDate1() {
      const current = new Date();
      const date = `${
        current.getMonth() + 1
      }-${current.getDate()}-${current.getFullYear()}`;
      return date;
    },
    newMonthCalender() {
      console.log("insadadsa");
      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.calendarManagement.name,
      });
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
    formatDate33(date) {
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    },
    formatDate(date) {
      return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
    },
    formatDate1(date) {
      return `${(date.getUTCMonth() + 1).toString().padStart(2, "0")}-${date
        .getUTCDate()
        .toString()
        .padStart(2, "0")}-${date.getUTCFullYear()}`;
    },
    goToNextDate33() {
      this.nextFlag = true;
      const next = new Date(this.currentDateDisplay);
      next.setDate(next.getDate() + 1);
      this.nextDate = this.formatDate(next);
      this.currentDateDisplay = this.nextDate;
      this.nextDate1 = this.formatDate1(next);
      this.currentDateDisplay1 = this.nextDate1;
    },
    goToNextDatess() {
      this.nextFlag = true;
      const nextDate = new Date(this.currentDateDisplay);
      nextDate.setUTCDate(nextDate.getUTCDate() + 1);
      this.nextDate = this.formatDate(nextDate);
      this.currentDateDisplay = this.nextDate;

      this.nextDate1 = this.formatDate1(nextDate);
      this.currentDateDisplay1 = this.nextDate1;
    },
    goToBackDatess() {
      this.nextFlag = true;
      const nextDate = new Date(this.currentDateDisplay);
      nextDate.setUTCDate(nextDate.getUTCDate() - 1);
      this.nextDate = this.formatDate(nextDate);
      this.currentDateDisplay = this.nextDate;
      this.nextDate1 = this.formatDate1(nextDate);
      this.currentDateDisplay1 = this.nextDate1;
    },
    goToNextDate() {
    this.nextFlag = true;
    const currentDate = moment(this.currentDateDisplay, 'YYYY-MM-DD');
    // Assuming 'YYYY-MM-DD' is the format of your date string, adjust it accordingly

    // Use add method for day manipulation
    this.nextDate = currentDate.clone().add(1, 'day').format('YYYY-MM-DD');
    this.currentDateDisplay = this.nextDate;

    this.nextDate1 = currentDate.clone().add(1, 'day').format('MM-DD-YYYY');
    // Adjust 'another-format' according to your needs
    this.currentDateDisplay1 = this.nextDate1;
},

goToBackDate() {
    this.nextFlag = true;
    const currentDate = moment(this.currentDateDisplay, 'YYYY-MM-DD');
    // Assuming 'YYYY-MM-DD' is the format of your date string, adjust it accordingly

    // Use subtract method for day manipulation
    this.nextDate = currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
    this.currentDateDisplay = this.nextDate;

    this.nextDate1 = currentDate.clone().subtract(1, 'day').format('MM-DD-YYYY');
    // Adjust 'another-format' according to your needs
    this.currentDateDisplay1 = this.nextDate1;
},
    goToNextDateList() {
      this.nextFlagList = true;
      const next = new Date(this.list_date);
      next.setDate(next.getDate() + 1);
      this.nextDate = this.formatDate(next);
      this.list_date = this.nextDate;
      this.nextDate1 = this.formatDate1(next);
      this.list_date1 = this.nextDate1;
    },

    goToBackDateList() {
      this.nextFlagList = true;
      const next = new Date(this.list_date);
      next.setDate(next.getDate() - 1);
      this.nextDate = this.formatDate(next);
      this.list_date = this.nextDate;
      this.nextDate1 = this.formatDate1(next);
      this.list_date1 = this.nextDate1;
    },
  },
  mounted() {
    this.getWeekDayCalendarData();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const todayIndex = new Date().getDay();
    this.today = daysOfWeek[todayIndex];
  },
};
</script>
