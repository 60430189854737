<style>
@media screen and (min-width: 750px) {
  html {
    overflow-y: auto;
  }
}
.v-application .white--text {
  color: #000 !important;
  caret-color: #000 !important;
  font-size: 12px !important;
  font-weight: bolder;
}
</style>
<template>
  <v-app>
    <div>
     <v-sheet tile class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="45px"
          style="border-radius: 10px;margin: 20px 5px "
           @click="newMonthCalender"
        >
          <span>Month</span>
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
        >
          <span>Week</span>
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
          @click="newDayCalender"
        >
          <span>Day</span>
        </v-btn>
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-4 col-sm-12 col-xs-12 text-center"
      >
        <v-spacer>
          <v-toolbar-title v-if="$refs.calendar" style="margin: 25px">
            <span
              @click="$refs.calendar.prev()"
              style="color: #38227a; font-size: 16px; cursor: pointer"
            >
              Prev
              <v-icon small> mdi-chevron-left </v-icon>
            </span>
            &nbsp;&nbsp;
            <b>{{ $refs.calendar.title }}</b
            >&nbsp;&nbsp;
            <span
              @click="$refs.calendar.next()"
              style="color: #38227a; font-size: 16px; cursor: pointer"
            >
              <v-icon small> mdi-chevron-right </v-icon>
              Next
            </span>
          </v-toolbar-title>
        </v-spacer>
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-4 col-sm-12 col-xs-12"
        style="text-align: right"
      >
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          @click="toggleAddEventModal({ show: true, type: 'add' })"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
        >
          <span>New Event</span>
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          @click="toggleAddTaskModal({ show: true, type: 'add' })"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
        >
          <span>New Task</span>
        </v-btn>
        <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          @click="toggleAddFilterModal({ show: true, type: 'add' })"
          height="45px"
          style="border-radius: 10px; margin: 20px 5px"
        >
          <span>Filter</span>
        </v-btn>
      </v-sheet>
      <div class="ma-4" style="height: 50%">
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card min-width="200px">
            <v-toolbar
              color="#38227A"
              dark
              v-if="this.selectedEvent.color == '#38227A'"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-toolbar
              color="#ADD8E6"
              dark
              v-else-if="this.selectedEvent.color == '#ADD8E6'"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-toolbar
              color="#FF0000"
              dark
              v-else-if="this.selectedEvent.color == '#FF0000'"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-toolbar
              color="#0000ff"
              dark
              v-else-if="this.selectedEvent.color == '#0000ff'"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-toolbar
              color="#000000"
              dark
              v-else-if="this.selectedEvent.color == '#000000'"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div class="text-center">
                {{ selectedEventStart }}
                to
                {{ selectedEventEnd }}
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-calendar
          ref="calendar"
          v-model="value"
          :events="events"
          color="primary"
          type="week"
          @click:event="showEvent"
          @change="updateRange"
           style="height: 650px"
        ></v-calendar>
        &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 130%">Note:</span
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <img
        src="@/assets/thingsToDo/gamecolordot.png"
        width="12px"
        height="12px"
      /><span style="color: #ffcccb; font-size: 125%; margin-left: 10px"
        >Games</span
      >&nbsp;&nbsp;&nbsp;&nbsp;
        <img
          src="@/assets/thingsToDo/paidassemblydot.png"
          width="12px"
          height="12px"
        />&nbsp;&nbsp;&nbsp;&nbsp;<span
          style="color: #ADD8E6;font-size: 125%; margin-left: -8px"
          >Hotel</span
        >&nbsp;&nbsp;&nbsp;
        <img
          src="@/assets/thingsToDo/bluedotnew.png"
          width="12px"
          height="12px"
        />
        <span style="color: #41a7f5; font-size: 125%; margin-left: 8px"
          >Assembly</span
        >&nbsp;&nbsp;
        <img
          src="@/assets/thingsToDo/campaigndot.png"
          width="12px"
          height="12px"
        /><span style="color: #8c6ee3; font-size: 125%; margin-left: 10px"
          >Wizfit</span
        >&nbsp;&nbsp;
        <img
          src="@/assets/thingsToDo/greendot.png"
          width="13px"
          height="13px"
        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
          style="color: #008000ad; font-size: 125%; margin-left: -16px"
          >Miscellaneous</span
        >&nbsp;&nbsp;
        <img
          src="@/assets/thingsToDo/pinkdot.png"
          width="13px"
          height="13px"
        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
          style="color: #f9b4f6; font-size: 125%; margin-left: -16px"
          >Task</span
        >&nbsp;&nbsp;
         <img
          src="@/assets/thingsToDo/ptodot.png"
          width="13px"
          height="13px"
        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
          style="color: #a3c585; font-size: 125%; margin-left: -16px"
          >PTO</span
        >&nbsp;&nbsp;&nbsp;&nbsp;
           <img
        src="@/assets/thingsToDo/customdot.png"
        width="13px"
        height="13px"
      />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
        style="color: #fbc02d; font-size: 125%; margin-left: -16px"
        >Custom Event</span
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src="@/assets/thingsToDo/kingdot.png"
        width="13px"
        height="13px"
      />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
        style="color: #27e600; font-size: 125%; margin-left: -16px"
        >ALLSTAR</span
      >&nbsp;&nbsp; &nbsp;&nbsp;
      <img
        src="@/assets/thingsToDo/bwydot.png"
        width="13px"
        height="13px"
      />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
        style="color: #b96a59; font-size: 125%; margin-left: -16px"
        >EMPIRE</span
      >&nbsp;&nbsp; &nbsp;&nbsp;
      <img
        src="@/assets/thingsToDo/swoopdot.png"
        width="13px"
        height="13px"
      />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
        style="color: #ca5cdd; font-size: 125%; margin-left: -16px"
        >LEGENDS</span
      >&nbsp;&nbsp;
      </div>
    </div>
    <add-event-modal></add-event-modal>
    <add-task-modal></add-task-modal>
    <add-filter-modal></add-filter-modal>
  </v-app>
</template>
<script>
import { API_CALENDAR_GET_DATA } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
import { ROUTER_URL } from "../../constants/urls";
import moment from "moment";
export default {
  name: "App",
  components: {
    AddEventModal: () => import("./AddEventModal/AddEventModal.vue"),
    AddTaskModal: () => import("./AddTaskModal/AddTaskModal.vue"),
    AddFilterModal: () => import("./AddFilterModal/AddFilterModal.vue"),
  },
  data: () => {
    return {
      focus: "",
      events: [],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },

        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      colors: ["blue", "black", "orange"],
      today: new Date(),
      firstcalldate: "",
      selectedOpen: false,
      selectedElement: undefined,
      selectedEvent: {},
      eventcategoryid: localStorage.getItem("eventid"),
      startdatefmin: localStorage.getItem("startdatefilter"),
      gameData: {},
      gamedisplaydate: "",
      assemblydisplaydatess: "",
      assemblyData: {},
      hotelData: {},
      hoteldisplaydatess: "",
    };
  },
  mounted() {
    this.$refs.calendar.scrollToTime("07:00");
  },
  methods: {
    ...mapActions({
      // calendar Modal
      toggleAddEventModal: "calendarManagement/toggleModal",
      toggleAddTaskModal: "calendarManagement/toggleTaskModal",
      toggleAddFilterModal: "calendarManagement/toggleFilterModal",
      togglePreviewModal: "calendarManagement/togglePreviewsModal",
    }),

    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}T24:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      this.firstdatemin = moment(min).format("YYYY-MM-DD");
      this.lastdatemin = moment(max).format("YYYY-MM-DD");
      this.startMonth = this.$refs.calendar.title;
      this.getMonthCalendarData();
    },
     getMonthCalendarData() {
      this.events = [];
      const self = this;
      self.loading = true;
      console.log("in mounted");
      const successHandler = (res) => {
        this.eventss = res.data.calendar_list;
        for (const items of this.eventss) {
          for (var a = 0; a < this.eventss.length; a++) {
            for (const itemsasem of this.eventss[a].assemblies) {
              if (items.assemble_id == itemsasem.id) {
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.player_list == null &&
                  itemsasem.team_name != "ALLSTAR" &&
                  itemsasem.team_name != "EMPIRE" &&
                  itemsasem.team_name != "SWOOP"
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                   const startdate31 = items.start_date;
                  const time = items.start_time;
                  var dateTime31 = startdate31 + " " + time;
                  const start = dateTime31;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids="3"
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "ALLSTAR" &&
                  itemsasem.player_list == null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                const startdate32 = items.start_date;
                  const time = items.start_time;
                  var dateTime32 = startdate32 + " " + time;
                  const start = dateTime32;
                  const color = "#27e600";
                  const id = items.assemble_id;
                        const eventids="3"
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "EMPIRE" &&
                  itemsasem.player_list == null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                 const startdate33 = items.start_date;
                  const time = items.start_time;
                  var dateTime33 = startdate33 + " " + time;
                  const start = dateTime33;
                  const color = "#b96a59";
                  const id = items.assemble_id;
                        const eventids="3"
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.team_name == "SWOOP" &&
                  itemsasem.player_list == null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                 const startdate34 = items.start_date;
                  const time = items.start_time;
                  var dateTime34 = startdate34 + " " + time;
                  const start = dateTime34;
                  const color = "#ca5cdd";
                  const id = items.assemble_id;
                        const eventids="3"
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list != null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.player_list[0].name +
                    "...)";
                  const startdate35 = items.start_date;
                  const time = items.start_time;
                  var dateTime35 = startdate35 + " " + time;
                  const start = dateTime35;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                        const eventids="3"
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list == null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA(Unassigned)";
                    const startdate36 = items.start_date;
                  const time = items.start_time;
                  var dateTime36 = startdate36 + " " + time;
                  const start = dateTime36;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                        const eventids="3"
                  this.events.push({ name, start, color, id,eventids });
                }
              }
            }
          }
      
          if (items.event_category == 8) {
            for (var q = 0; q < this.eventss.length; q++) {
              for (const itemspaidevens of this.eventss[q].paid_events) {
                if (items.paid_event_id == itemspaidevens.id) {
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name != "ALLSTAR" &&
                    itemspaidevens.team_name != "EMPIRE" &&
                    itemspaidevens.team_name != "SWOOP" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =itemspaidevens.event_name +"- "+
                      itemspaidevens.city.toUpperCase() +
                      "," +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                     const startdatecustom = itemspaidevens.date;
                  const timecustom = itemspaidevens.start_time;
                   var dateTimecustom = startdatecustom + " " + timecustom 
                  const start = dateTimecustom 
                  const endtimecustom = itemspaidevens.end_time;
                  var enddateTimecustom = startdatecustom + " " + endtimecustom 
                  const end = enddateTimecustom 
                    const color = "#FBC02D";
                    const id = itemspaidevens.id;
                    const eventids=8;
                    this.events.push({ name, start,end, color, id ,eventids});
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "ALLSTAR" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =itemspaidevens.event_name +"- "+
                      itemspaidevens.city.toUpperCase() +
                      "," +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                     const startdatecustom81 = itemspaidevens.date;
                  const timecustom = itemspaidevens.start_time;
                   var dateTimecustom81 = startdatecustom81 + " " + timecustom 
                  const start = dateTimecustom81 
                  const endtimecustom = itemspaidevens.end_time;
                  var enddateTimecustom81 = startdatecustom81+ " " + endtimecustom 
                  const end = enddateTimecustom81 
                    const color = "#27e600";
                    const id = itemspaidevens.id;
                     const eventids=8;
                    this.events.push({ name, start, end,color, id,eventids });
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "EMPIRE" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =itemspaidevens.event_name +"- "+
                      itemspaidevens.city.toUpperCase() +
                      "," +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                      const startdatecustom82 = itemspaidevens.date;
                  const timecustom = itemspaidevens.start_time;
                   var dateTimecustom82 = startdatecustom82 + " " + timecustom 
                  const start = dateTimecustom82 
                  const endtimecustom = itemspaidevens.end_time;
                  var enddateTimecustom82 = startdatecustom82+ " " + endtimecustom 
                  const end = enddateTimecustom82 
                    const color = "#b96a59";
                    const id = itemspaidevens.id;
                     const eventids=8;
                    this.events.push({ name, start,end, color, id,eventids });
                  }
                  if (
                    itemspaidevens.team_name != null &&
                    itemspaidevens.team_name == "SWOOP" &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =itemspaidevens.event_name +"- "+
                      itemspaidevens.city.toUpperCase() +
                      "," +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.team_name +
                      " )";
                   const startdatecustom83 = itemspaidevens.date;
                  const timecustom = itemspaidevens.start_time;
                   var dateTimecustom83 = startdatecustom83 + " " + timecustom 
                  const start = dateTimecustom83
                  const endtimecustom = itemspaidevens.end_time;
                  var enddateTimecustom83 = startdatecustom83+ " " + endtimecustom 
                  const end = enddateTimecustom83 
                    const color = "#ca5cdd";
                    const id = itemspaidevens.id;
                     const eventids=8;
                    this.events.push({ name, start,end, color, id,eventids });
                  }
                  if (
                    itemspaidevens.team == null &&
                    itemspaidevens.player_list != null
                  ) {
                    const name =itemspaidevens.event_name +"- "+
                      itemspaidevens.city.toUpperCase() +
                      "," +
                      itemspaidevens.state_name +
                      "-(" +
                      itemspaidevens.player_list[0].name +
                      " ...)";
                    const startdatecustom84 = itemspaidevens.date;
                  const timecustom = itemspaidevens.start_time;
                   var dateTimecustom84 = startdatecustom84 + " " + timecustom 
                  const start = dateTimecustom84 
                  const endtimecustom = itemspaidevens.end_time;
                  var enddateTimecustom84 = startdatecustom84+ " " + endtimecustom 
                  const end = enddateTimecustom84 
                    const color = "#FBC02D";
                    const id = itemspaidevens.id;
                     const eventids=8;
                    this.events.push({ name, start,end, color, id ,eventids});
                  }

                  if (
                    itemspaidevens.team == null &&
                    itemspaidevens.player_list == null
                  ) {
                    const name =itemspaidevens.event_name +"- "+
                      itemspaidevens.city.toUpperCase() +
                      "," +
                      itemspaidevens.state_name +
                      "-(Unassigned)";
                    const startdatecustom85 = itemspaidevens.date;
                  const timecustom = itemspaidevens.start_time;
                   var dateTimecustom85 = startdatecustom85 + " " + timecustom 
                  const start = dateTimecustom85 
                  const endtimecustom = itemspaidevens.end_time;
                  var enddateTimecustom85 = startdatecustom85+ " " + endtimecustom 
                  const end = enddateTimecustom85 
                    const color = "#FBC02D";
                    const id = itemspaidevens.id;
                     const eventids=8;
                    this.events.push({ name, start,end, color, id,eventids });
                  }
                }
              }
            }
          }

          for (var b = 0; b < this.eventss.length; b++) {
            for (const itemsasemh of this.eventss[b].hotel_detail) {
              if (items.hotel_id == itemsasemh.id) {
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.hotel_team_name != null &&
                  itemsasemh.hotel_team_name != "ALLSTAR" &&
                  itemsasemh.hotel_team_name != "EMPIRE" &&
                  itemsasemh.hotel_team_name != "SWOOP" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.hotel_team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#ADD8E6";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id ,eventids});
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.hotel_team_name != null &&
                  itemsasemh.hotel_team_name == "ALLSTAR" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.hotel_team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#27e600";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.hotel_team_name != null &&
                  itemsasemh.hotel_team_name == "EMPIRE" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.hotel_team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#b96a59";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.hotel_team_name != null &&
                  itemsasemh.hotel_team_name == "SWOOP" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.hotel_team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#ca5cdd";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.hotel_team_name == null &&
                  itemsasemh.player_list != null
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.player_list[0].name +
                    "...)";
                  const start = items.start_date;
                  const color = "#ADD8E6";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.hotel_team_name == null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.hotel_city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const color = "#ADD8E6";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }

                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null && itemsasemh.team_name!="ALLSTAR" && itemsasemh.team_name!="EMPIRE" && itemsasemh.team_name!="SWOOP" &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#ADD8E6";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null && itemsasemh.team_name=="ALLSTAR"  &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#27e600";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null && itemsasemh.team_name=="EMPIRE"  &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#b96a59";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null && itemsasemh.team_name=="SWOOP"  &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#ca5cdd";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list != null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.player_list[0].name +
                    "...)";
                  const start = items.start_date;
                  const color = "#ADD8E6";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id,eventids });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city.toUpperCase() +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const color = "#ADD8E6";
                  const id = items.hotel_id;
                  const eventids="4";
                  this.events.push({ name, start, color, id ,eventids});
                }
              }
            }
          }
          if (items.event_category == 5) {
            const name = items.task_title;
            const start = items.start_date;
            const end = items.end_date;
            const color = "#F9B4F6";
            const id = items.id;
            this.events.push({ name, start, end, color, id });
          }
          if (items.event_category == 7) {
            const name = "PTO-(" + items.player_name + ")";
            const start = items.start_date;
            const end = items.end_date;
            const color = "#a3c585";
            const id = items.id;
            this.events.push({ name, start, end, color, id });
          }
          for (var i = 0; i < this.eventss.length; i++) {
            for (const itemgame of this.eventss[i].games) {
              if (items.game_id == itemgame.id) {
                if (
                  itemgame.team_name == null &&
                  itemgame.player_list != null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.player_list[0].name +
                    "...)";
                 const startdate = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame = startdate + " " + time;
                  const start = dateTimegame;
                  const endtime = itemgame.end_time;
                  var enddateTimegame = startdate + " " + endtime;
                  const end = enddateTimegame;
                  const color = "#ffcccb";
                  const id = itemgame.id;
                  const eventids = "1";
                  this.events.push({ name, start,end, color, id,eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "ALLSTAR" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                const startdate11 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame11 = startdate11 + " " + time;
                  const start = dateTimegame11;
                  const endtime = itemgame.end_time;
                  var enddateTimegame11 = startdate11 + " " + endtime;
                  const end = enddateTimegame11;
                  const color = "#27e600";
                  const id = itemgame.id;
                   const eventids = "1";
                  this.events.push({ name, start,end, color, id,eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "EMPIRE" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate12 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame12 = startdate12 + " " + time;
                  const start = dateTimegame12;
                  const endtime = itemgame.end_time;
                  var enddateTimegame12 = startdate12 + " " + endtime;
                  const end = enddateTimegame12;
                  const color = "#b96a59";
                  const id = itemgame.id;
                   const eventids = "1";
                  this.events.push({ name, start,end, color, id ,eventids});
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name == "SWOOP" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate13 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame13 = startdate13 + " " + time;
                  const start = dateTimegame13;
                  const endtime = itemgame.end_time;
                  var enddateTimegame13 = startdate13 + " " + endtime;
                  const end = enddateTimegame13;
                  const color = "#ca5cdd";
                  const id = itemgame.id;
                   const eventids = "1";
                  this.events.push({ name, start,end, color, id,eventids });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.team_name != "ALLSTAR" &&
                  itemgame.team_name != "EMPIRE" &&
                  itemgame.team_name != "SWOOP" &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate14 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame14 = startdate14 + " " + time;
                  const start = dateTimegame14;
                  const endtime = itemgame.end_time;
                  var enddateTimegame14 = startdate14 + " " + endtime;
                  const end = enddateTimegame14;
                  const color = "#ffcccb";
                  const id = itemgame.id;
                 const eventids = "1";
                  this.events.push({ name, start,end, color, id, eventids });
                }

                if (
                  itemgame.team_name == null &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    itemgame.start_time_str +
                    "(Unassigned)";
                  const startdate15 = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame15 = startdate15 + " " + time;
                  const start = dateTimegame15;
                  const endtime = itemgame.end_time;
                  var enddateTimegame15 = startdate15 + " " + endtime;
                  const end = enddateTimegame15;
                  const color = "#ffcccb";
                  const id = itemgame.id;
                   const eventids = "1";
                  this.events.push({ name, start,end ,color, id,eventids });
                }
              }
            }
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team != null &&
            items.campaigns[0].kick_off_player_list == null &&
            items.campaigns[0].kick_off_team_name != "ALLSTAR" &&
            items.campaigns[0].kick_off_team_name != "EMPIRE" &&
            items.campaigns[0].kick_off_team_name != "SWOOP"
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const startko = items.start_date;
            const timeko = items.start_time;
            var dateTimeko = startko + " " + timeko;
            const start = dateTimeko;
            const endko = items.start_date;
            const endtimeko = items.end_time;
            var dateTimeendko = endko + " " + endtimeko;
            const end = dateTimeendko;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team != null &&
            items.campaigns[0].kick_off_team_name == "ALLSTAR" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")";

           const startko1 = items.start_date;
            const timeko = items.start_time;
            var dateTimeko1 = startko1 + " " + timeko;
            const start = dateTimeko1;
            const endko = items.start_date;
            const endtimeko1 = items.end_time;
            var dateTimeendko1 = endko + " " + endtimeko1;
            const end = dateTimeendko1;
            const color = "#27e600";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team != null &&
            items.campaigns[0].kick_off_team_name == "EMPIRE" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const startko12 = items.start_date;
            const timeko = items.start_time;
            var dateTimeko12 = startko12 + " " + timeko;
            const start = dateTimeko12;
            const endko = items.start_date;
            const endtimeko12 = items.end_time;
            var dateTimeendko12 = endko + " " + endtimeko12;
            const end = dateTimeendko12;
            const color = "#b96a59";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_team != null &&
            items.campaigns[0].kick_off_team_name == "SWOOP" &&
            items.campaigns[0].kick_off_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_team_name +
                ")";

            const startko13 = items.start_date;
            const timeko = items.start_time;
            var dateTimeko13 = startko13 + " " + timeko;
            const start = dateTimeko13;
            const endko = items.start_date;
            const endtimeko13 = items.end_time;
            var dateTimeendko13 = endko + " " + endtimeko13;
            const end = dateTimeendko13;
            const color = "#ca5cdd";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list != null &&
            items.campaigns[0].kick_off_team == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_player_list[0].name +
                "...)"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-" +
                items.campaigns[0].kick_off_player_list[0].name +
                "...)";

           const startko14 = items.start_date;
            const timeko = items.start_time;
            var dateTimeko14 = startko14 + " " + timeko;
            const start = dateTimeko14;
            const endko = items.start_date;
            const endtimeko14 = items.end_time;
            var dateTimeendko14 = endko + " " + endtimeko14;
            const end = dateTimeendko14;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "kick_off_date" &&
            items.campaigns[0].kick_off_player_list == null &&
            items.campaigns[0].kick_off_team == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-Unassigned)"
              : " WF -" +
                items.district_name +
                "  - KO (" +
                items.campaigns[0].kick_off_presence +
                "-Unassigned)";

           const startko15 = items.start_date;
            const timeko = items.start_time;
            var dateTimeko15 = startko15 + " " + timeko;
            const start = dateTimeko15;
            const endko = items.start_date;
            const endtimeko15 = items.end_time;
            var dateTimeendko15 = endko + " " + endtimeko15;
            const end = dateTimeendko15;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team != null &&
            items.campaigns[0].gold_ball_team_name != "ALLSTAR" &&
            items.campaigns[0].gold_ball_team_name != "EMPIRE" &&
            items.campaigns[0].gold_ball_team_name != "SWOOP" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")";

            const startgb = items.start_date;
            const timegb = items.start_time;
            var dateTimegb = startgb + " " + timegb;
            const start = dateTimegb;
            const endgb = items.start_date;
            const endtimegb = items.end_time;
            var dateTimeendgb = endgb + " " + endtimegb;
            const end = dateTimeendgb;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id ,eventids});
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team != null &&
            items.campaigns[0].gold_ball_team_name == "ALLSTAR" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")";

             const startgb1 = items.start_date;
            const timegb = items.start_time;
            var dateTimegb1 = startgb1 + " " + timegb;
            const start = dateTimegb1;
            const endgb = items.start_date;
            const endtimegb1 = items.end_time;
            var dateTimeendgb1 = endgb + " " + endtimegb1;
            const end = dateTimeendgb1;
            const color = "#27e600";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id ,eventids});
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team != null &&
            items.campaigns[0].gold_ball_team_name == "EMPIRE" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")";

           const startgb12 = items.start_date;
            const timegb = items.start_time;
            var dateTimegb12 = startgb12 + " " + timegb;
            const start = dateTimegb12;
            const endgb = items.start_date;
            const endtimegb12 = items.end_time;
            var dateTimeendgb12 = endgb + " " + endtimegb12;
            const end = dateTimeendgb12;
            const color = "#b96a59";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id ,eventids});
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_team != null &&
            items.campaigns[0].gold_ball_team_name == "SWOOP" &&
            items.campaigns[0].gold_ball_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_team_name +
                ")";

               const startgb13 = items.start_date;
            const timegb = items.start_time;
            var dateTimegb13 = startgb13 + " " + timegb;
            const start = dateTimegb13;
            const endgb = items.start_date;
            const endtimegb13 = items.end_time;
            var dateTimeendgb13 = endgb + " " + endtimegb13;
            const end = dateTimeendgb13;
            const color = "#ca5cdd";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list != null &&
            items.campaigns[0].gold_ball_team == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_player_list[0].name +
                "...)"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "-" +
                items.campaigns[0].gold_ball_player_list[0].name +
                "...)";

           const startgb14 = items.start_date;
            const timegb = items.start_time;
            var dateTimegb14 = startgb14 + " " + timegb;
            const start = dateTimegb14;
            const endgb = items.start_date;
            const endtimegb14 = items.end_time;
            var dateTimeendgb14 = endgb + " " + endtimegb14;
            const end = dateTimeendgb14;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "gold_ball_giveaway_date" &&
            items.campaigns[0].gold_ball_player_list == null &&
            items.campaigns[0].gold_ball_team == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "- Unassigned)"
              : " WF -" +
                items.district_name +
                "  - GB (" +
                items.campaigns[0].gold_ball_presence +
                "- Unassigned)";

             const startgb15 = items.start_date;
            const timegb = items.start_time;
            var dateTimegb15 = startgb15 + " " + timegb;
            const start = dateTimegb15;
            const endgb = items.start_date;
            const endtimegb15 = items.end_time;
            var dateTimeendgb15 = endgb + " " + endtimegb15;
            const end = dateTimeendgb15;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team != null &&
            items.campaigns[0].graduation_team_name != "ALLSTAR" &&
            items.campaigns[0].graduation_team_name != "EMPIRE" &&
            items.campaigns[0].graduation_team_name != "SWOOP" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")";

            const startgr = items.start_date;
            const timegr = items.start_time;
            var dateTimegr = startgr + " " + timegr;
            const start = dateTimegr;
            const endgr = items.start_date;
            const endtimegr = items.end_time;
            var dateTimeendgr = endgr + " " + endtimegr;
            const end = dateTimeendgr;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team != null &&
            items.campaigns[0].graduation_team_name == "ALLSTAR" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")";

             const startgr1 = items.start_date;
            const timegr = items.start_time;
            var dateTimegr1 = startgr1 + " " + timegr;
            const start = dateTimegr1;
            const endgr = items.start_date;
            const endtimegr1 = items.end_time;
            var dateTimeendgr1 = endgr + " " + endtimegr1;
            const end = dateTimeendgr1;
            const color = "#27e600";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team != null &&
            items.campaigns[0].graduation_team_name == "EMPIRE" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")";

             const startgr12 = items.start_date;
            const timegr = items.start_time;
            var dateTimegr12 = startgr12 + " " + timegr;
            const start = dateTimegr12;
            const endgr = items.start_date;
            const endtimegr12 = items.end_time;
            var dateTimeendgr12 = endgr + " " + endtimegr12;
            const end = dateTimeendgr12;
            const color = "#b96a59";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_team != null &&
            items.campaigns[0].graduation_team_name == "SWOOP" &&
            items.campaigns[0].graduation_player_list == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_team_name +
                ")";

           const startgr13 = items.start_date;
            const timegr = items.start_time;
            var dateTimegr13 = startgr13 + " " + timegr;
            const start = dateTimegr13;
            const endgr = items.start_date;
            const endtimegr13 = items.end_time;
            var dateTimeendgr13 = endgr + " " + endtimegr13;
            const end = dateTimeendgr13;
            const color = "#ca5cdd";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list != null &&
            items.campaigns[0].graduation_team == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_player_list[0].name +
                "...)"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-" +
                items.campaigns[0].graduation_player_list[0].name +
                "...)";

            const startgr14 = items.start_date;
            const timegr = items.start_time;
            var dateTimegr14 = startgr14 + " " + timegr;
            const start = dateTimegr14;
            const endgr = items.start_date;
            const endtimegr14 = items.end_time;
            var dateTimeendgr14 = endgr + " " + endtimegr14;
            const end = dateTimeendgr14;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end, color, id,eventids });
          }
          if (
            items.event_type == "graduation_celebration_date" &&
            items.campaigns[0].graduation_player_list == null &&
            items.campaigns[0].graduation_team == null
          ) {
            const name = items.school_name
              ? " WF -" +
                items.school_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-Unassigned)"
              : " WF -" +
                items.district_name +
                "  - GR (" +
                items.campaigns[0].graduation_presence +
                "-Unassigned)";

           const startgr15 = items.start_date;
            const timegr = items.start_time;
            var dateTimegr15 = startgr15 + " " + timegr;
            const start = dateTimegr15;
            const endgr = items.start_date;
            const endtimegr15 = items.end_time;
            var dateTimeendgr15 = endgr + " " + endtimegr15;
            const end = dateTimeendgr15;
            const color = "#8c6ee39e";
            const id = items.campaign_id;
            const eventids="2"
            this.events.push({ name, start,end ,color, id,eventids })
          }
        }
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      formData.start_date = this.firstdatemin;
      formData.end_date = this.lastdatemin;
      // formData.start_date = this.startdatefmin;
      //formData.end_date = this.enddatefmin;
      formData.event_category_id = this.eventcategoryid;
      if (this.teamID) {
        formData.team_id = this.teamID;
      }
      localStorage.setItem("filterdata", this.eventcategoryid);
      Axios.request_GET(
        API_CALENDAR_GET_DATA,

        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    fetchEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
          category: this.categories[this.rnd(0, this.categories.length - 1)],
        });
      }

      this.events = events;
    },

    newMonthCalender() {
      console.log("insadadsa");
      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.calendarManagement.name,
      });
    },
    newDayCalender() {
      console.log("insadadsa");
      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.daycalendarManagement.name,
      });
    },
    dateChange(date) {
      console.log(date);
    },
    setToday() {
      this.focus = "";
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
  computed: {
    selectedEventStart() {
      return this.selectedEvent && this.selectedEvent.start;
    },
    selectedEventEnd() {
      return this.selectedEvent && this.selectedEvent.end;
    },
  },
};
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>
