<template>
  <div class="px-7 py-10">
    <v-icon size="32" color="#2D2D2D" @click="Goback()">mdi-arrow-left</v-icon>
    <v-card class="mt-10">
      <v-card-title class="py-3">
        <v-row justify="center">
          <v-col> </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <br />
        <v-row>
          <div class="card-text">
            <p class="heading" style="margin-left: 11px">Upload File</p>
          </div>
          <br />
        </v-row>
        <br />
        <v-row no-gutters>
          <v-col cols="6">
            <label style="font-size: medium">Upload File</label>
            <v-file-input
              v-model="file"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              outlined
              color="#7254CF"
              dense
              clearable
              :disabled="formLoading"
            >
            </v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="3" class="text-right">
            <v-btn
              dark
              style="border-radius: 10px"
              class="mr-4 text-capitalize px-16"
              color="#38227A"
              height="45"
              @click="submitBulkUpload()"
              :loading="submitLoading"
            >
              <span>Submit</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { API_UPDATE_VIDEO_URL } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
export default {
  name: "Upload",
  data() {
    return {
      file: {},
      routeName: "",
      formLoading: false,
      submitLoading: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),
    submitBulkUpload() {
      if (this.file instanceof File) {
        this.submitLoading = true;
        const successHandler = (res) => {
          console.log(res);
          if (res.data.success) {
            this.showToast({
              message: res.data.message,
              color: "s",
            });
            this.file = {};
          } else {
            this.showToast({
              message:
                "This file has invalid fields, To find out download the file and try again.",
              color: "e",
            });
            setTimeout(() => {
              var fileURL = window.URL.createObjectURL(new Blob([res.data]));
              var fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "fieldError.csv");
              document.body.appendChild(fileLink);

              fileLink.click();
            }, 1000);
          }
          this.file = null;
          this.submitLoading = false;
        };
        const failureHandler = () => {
          this.submitLoading = false;
        };
        let formData = new FormData();

        formData.append("file", this.file);
        Axios.request_POST(
          API_UPDATE_VIDEO_URL,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        //toggle
        this.showToast({
          message: "Select a File first",
          color: "w",
        });
      }
    },
     Goback(){
      this.$router.go(-1)
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.v-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.card-text {
  color: black;
}
.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}
.paragraph {
  margin-top: 5px;
  font-size: 17px;
}
.v-card__title {
  text-align: center;
  background-color: #d30024;
  color: white;
  min-height: 50px;
  font-family: Roboto Slab;
}
.v-input {
  background: #ffffff;
  border-radius: 6px;
}
.v-btn >>> span {
  font-size: 17px;
  font-weight: 600;
}
.btn.text-right.px-2.col-sm-3.col-12 {
  padding-top: 50px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .btn.text-right.px-2.col-sm-3.col-12 {
    padding-top: 0px;
  }
  .card-heading {
    position: absolute;
    left: 130px;
  }
  .card.v-card {
    max-height: 300px;
  }
  .btn {
    position: static;
  }
  .btn1 {
    position: static;
  }
  .v-input {
    margin-bottom: 20px;
  }
}
</style>
